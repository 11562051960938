import React from 'react'
import CircularProgress from '@material-ui/core/CircularProgress'
import { withStyles } from '@material-ui/core/styles'
import getEntityLink from '~/utils/getEntityLink'
import Link from '~/components/Link'

const styles = (theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
  },
  artwork: {
    height: 130,
    backgroundPosition: 'center',
    backgroundSize: 'cover',
  },
  padding: {
    padding: theme.spacing(2),
  },
})

const asDefaultPopup = (mapping) => (Component) => {
  class AsDefaultPopup extends React.Component {
    componentDidUpdate = () => {
      const {
        onLoaded,
        data: { loading },
      } = this.props
      if (!loading) {
        onLoaded && onLoaded()
      }
    }
    render() {
      const { classes, ...props } = this.props
      const { thumbnail, padding, slug } = mapping ? mapping(this.props) : {}
      if (this.props.data.loading) {
        return <CircularProgress />
      }
      return (
        <div className={classes.root}>
          {thumbnail && (
            <Link {...getEntityLink(this.props.objectId, slug)}>
              {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
              <a target="_parent">
                <div
                  className={classes.artwork}
                  style={{
                    backgroundImage: `url(${thumbnail})`,
                  }}
                />
              </a>
            </Link>
          )}
          <div className={padding ? classes.padding : ''}>
            <Component {...props} />
          </div>
        </div>
      )
    }
  }
  return withStyles(styles)(AsDefaultPopup)
}

export default asDefaultPopup
