import React from 'react'
import compose from 'recompose/compose'
import { withStyles } from '@material-ui/core/styles'
import Typo from '~/components/Typo'
import { ENTITIES_LABEL } from '~/constantes'
import Years from '~/components/Years'
import Chip from '@material-ui/core/Chip'
import Grid from '@material-ui/core/Grid'
import withIntersectionObserver from '~/utils/withIntersectionObserver'
import toClass from 'recompose/toClass'
import Typography from '@material-ui/core/Typography'
import Slide from '@material-ui/core/Slide'
import Avatar from '@material-ui/core/Avatar'
import Box from '@material-ui/core/Box'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import EntityLink from '~/components/EntityLink'

const TimelineItemComponent = ({
  isVisible,
  start,
  end,
  classes,
  skipDate,
  children,
  ...props
}) => (
  <div className={classes.root}>
    <Slide direction="left" in={isVisible} timeout={500}>
      <Grid container alignItems="stretch" {...props}>
        <Grid
          item
          xs={12}
          md={1}
          className={clsx(classes.title, {
            [classes.withDate]: !skipDate,
          })}
        ></Grid>
        <Grid item xs={12} md={11}>
          <div className={classes.body}>
            {!skipDate && (
              <Box mb={2}>
                <Chip
                  color="primary"
                  label={
                    <Years start={start} end={end} className={classes.year} />
                  }
                />
              </Box>
            )}
            {children}
          </div>
        </Grid>
      </Grid>
    </Slide>
  </div>
)

const TimelineItem = compose(
  withStyles((theme) => ({
    root: {
      padding: '0 10px',
      '& $title, & $body': {
        [theme.breakpoints.down('sm')]: {
          paddingTop: 10,
          paddingBottom: 10,
        },
      },
      overflow: 'hidden',
    },
    year: {
      color: 'white',
    },
    title: {
      paddingTop: 40,
      paddingBottom: 40,
      paddingRight: 20,
      textAlign: 'right',
      position: 'relative',
      borderRight: `4px solid ${theme.palette.primary[200]}`,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
    },
    withDate: {
      '&:before': {
        content: '""',
        width: 12,
        height: 12,
        borderRadius: '100%',
        border: `2px solid ${theme.palette.primary.main}`,
        backgroundColor: 'white',
        position: 'absolute',
        right: -8,
        top: 'calc(50% - 6px)',
      },
    },
    body: {
      paddingLeft: 40,
      paddingTop: 40,
      paddingBottom: 40,
      [theme.breakpoints.down('sm')]: {
        borderRight: `4px solid ${theme.palette.primary[200]}`,
      },
    },
  })),
  withIntersectionObserver(0),
  toClass
)(TimelineItemComponent)

const styles = (theme) => ({
  root: {},
  item: {
    display: 'flex',
  },
})

const Timeline = ({ items, classes }) => {
  return (
    <div className={classes.root}>
      {items.map(
        (
          { id, slug, name, type, thumbnail, startDate, endDate },
          index,
          self
        ) => (
          <TimelineItem
            key={id}
            start={startDate}
            end={endDate}
            skipDate={
              index > 0 &&
              self[index - 1].startDate === startDate &&
              self[index - 1].endDate === endDate
            }
          >
            <div className={classes.item}>
              <div>
                <div>
                  {type === 'Person' && <Typography>Naissance de</Typography>}
                  {type === 'Group' && <Typography>Formation de</Typography>}
                  {type === 'Track' && <Typography>Composition de</Typography>}
                  {type === 'Style' && <Typography>Apparition du</Typography>}
                  <EntityLink id={id} slug={slug} name={name} />
                </div>
                <Typo variant="subheading">{ENTITIES_LABEL[type]}</Typo>
              </div>
              {thumbnail && (
                <Avatar
                  style={{ width: 50, height: 50, marginLeft: 20 }}
                  src={thumbnail}
                />
              )}
            </div>
          </TimelineItem>
        )
      )}
    </div>
  )
}
Timeline.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      slug: PropTypes.string,
      name: PropTypes.string,
      type: PropTypes.string,
      thumbnail: PropTypes.string,
      startDate: PropTypes.string,
      endDate: PropTypes.string,
    })
  ),
}

export default compose(withStyles(styles))(Timeline)
