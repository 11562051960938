import React from 'react'
import { graphql } from '@apollo/react-hoc'
import gql from 'graphql-tag'
import compose from 'recompose/compose'
import Typography from '@material-ui/core/Typography'
import get from 'lodash/get'
import Years from '~/components/Years'
import asDefaultPopup from './asDefaultPopup'
import { ENTITIES_LABEL } from '~/constantes'
import EntityLink from '~/components/EntityLink'
import EntityRef from '~/components/MetaTabs/EntitiesRef'
import uniqBy from 'lodash/uniqBy'

class GroupView extends React.Component {
  render() {
    const {
      data: { group },
    } = this.props
    return (
      <div>
        <EntityLink
          name={group.name}
          id={group.id}
          slug={group.slug}
          noTooltip
        />
        <Typography>{ENTITIES_LABEL.Group}</Typography>
        <Typography variant="body2">{group.birthPlace}</Typography>
        {group.endDate && (
          <Years start={group.startDate} end={group.endDate} variant="body2" />
        )}
        <EntityRef
          entities={uniqBy(group.members.edges, 'node.id')}
          noLink
          style={{ marginTop: 20 }}
        />
      </div>
    )
  }
}

export default compose(
  graphql(gql`
    query getGroupForMap($objectId: ID!) {
      group(id: $objectId) {
        id
        slug
        birthPlace
        startDate
        endDate
        name
        thumbnail
        members {
          edges {
            node {
              id
              name
              slug
            }
          }
        }
      }
    }
  `),
  asDefaultPopup((props) => ({
    thumbnail: get(props.data, 'group.thumbnail'),
    slug: get(props.data, 'group.slug'),
    padding: true,
  }))
)(GroupView)
