import React from 'react'
import { graphql } from '@apollo/react-hoc'
import gql from 'graphql-tag'
import compose from 'recompose/compose'
import Typography from '@material-ui/core/Typography'
import get from 'lodash/get'
import asDefaultPopup from './asDefaultPopup'
import { ENTITIES_LABEL } from '~/constantes'
import EntityLink from '~/components/EntityLink'
import Years from '~/components/Years'

class StyleView extends React.Component {
  render() {
    const {
      data: { style },
    } = this.props
    return (
      <div>
        <EntityLink
          name={style.name}
          id={style.id}
          slug={style.slug}
          noTooltip
        />
        <Typography>{ENTITIES_LABEL.Style}</Typography>
        <Years start={style.startDate} end={style.endDate} variant="body2" />
      </div>
    )
  }
}

export default compose(
  graphql(gql`
    query getStyleTooltip($objectId: ID!) {
      style(id: $objectId) {
        id
        name
        slug
        thumbnail
        locationName
        startDate
        endDate
      }
    }
  `),
  asDefaultPopup((props) => ({
    thumbnail: get(props.data, 'style.thumbnail'),
    slug: get(props.data, 'style.slug'),
    padding: true,
  }))
)(StyleView)
