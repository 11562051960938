import React from 'react'
import { graphql } from '@apollo/react-hoc'
import gql from 'graphql-tag'
import compose from 'recompose/compose'
import Typography from '@material-ui/core/Typography'
import get from 'lodash/get'
import Years from '~/components/Years'
import asDefaultPopup from './asDefaultPopup'
import { ENTITIES_LABEL } from '~/constantes'
import EntityLink from '~/components/EntityLink'

class TagView extends React.Component {
  render() {
    const {
      data: { tag },
    } = this.props
    return (
      <div>
        <EntityLink name={tag.name} id={tag.id} slug={tag.slug} noTooltip />
        <Typography>{ENTITIES_LABEL.Tag}</Typography>
        <Typography variant="body2">
          {tag.description.slice(0, 200)}
          {tag.description && tag.description.length > 200 && '...'}
        </Typography>
        <Typography variant="body2">{tag.locationName}</Typography>
        <Years start={tag.startDate} end={tag.endDate} variant="body2" />
      </div>
    )
  }
}

export default compose(
  graphql(gql`
    query getTagForMap($objectId: ID!) {
      tag(id: $objectId) {
        id
        name
        slug
        description
        locationName
        startDate
        endDate
        thumbnail
      }
    }
  `),
  asDefaultPopup((props) => ({
    thumbnail: get(props.data, 'tag.thumbnail'),
    slug: get(props.data, 'tag.slug'),
    padding: true,
  }))
)(TagView)
