import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import Timeline from '~/components/Timeline'
import uniqBy from 'lodash/uniqBy'
import sortBy from 'lodash/sortBy'
import { useQuery } from '@apollo/react-hooks'
import getEntitiesForContext from './queries/getEntitiesForContext'
import {
  getPostsForPostListContext,
  getPostsForPostListContext_postList_posts_edges,
  getPostsForPostListContextVariables,
  getPostsForPostListContext_postList_posts_edges_node_entities_edges_node,
} from '~/queries.d.ts'

const useStyles = makeStyles(() => ({
  root: {
    marginTop: 60,
    maxWidth: 800,
    margin: 'auto',
  },
  timeline: {
    marginTop: 40,
  },
}))

interface Props {
  postList: {
    slug: string
  }
}

type Entity = getPostsForPostListContext_postList_posts_edges_node_entities_edges_node & {
  startDate: string
  endDate?: string
}

function getEntities(
  postsEdges: (getPostsForPostListContext_postList_posts_edges | null)[]
) {
  if (!postsEdges) {
    return []
  }
  let entites: Entity[] = []

  postsEdges.forEach((edge) => {
    if (edge && edge.node && edge.node.entities) {
      edge.node.entities.edges.forEach((edge) => {
        if (
          edge?.node &&
          (('startDate' in edge.node && edge.node.startDate) ||
            ('styleStartDate' in edge.node && edge.node.styleStartDate))
        ) {
          let startDate: string
          if ('startDate' in edge.node) {
            startDate = edge.node.startDate
          } else {
            startDate = edge.node.styleStartDate
          }
          entites.push({
            ...edge.node,
            startDate,
          })
        }
      })
    }
  })

  let entitiesList = uniqBy(entites, 'id').map((d) => ({
    startDate: d.startDate.slice(0, 4),
    endDate: d.endDate ? d.endDate.slice(0, 4) : null,
    ...d,
  }))
  entitiesList = sortBy(entitiesList, ['startDate'])
  return entitiesList
}

const Context = ({ postList }: Props) => {
  const { loading, data } = useQuery<
    getPostsForPostListContext,
    getPostsForPostListContextVariables
  >(getEntitiesForContext, { variables: { slug: postList.slug } })
  const classes = useStyles()
  let entities =
    data && data.postList && data.postList.posts
      ? getEntities(data.postList.posts.edges)
      : []
  return (
    <div className={classes.root}>
      <div className={classes.timeline}>
        {!loading && entities.length > 0 && <Timeline items={entities} />}
        {!loading && entities.length === 0 && (
          <Typography>Il n'y a pas d'entité datée dans ce parcours.</Typography>
        )}
      </div>
    </div>
  )
}

export default Context
