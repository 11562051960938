import React from 'react'
import Typography from '@material-ui/core/Typography'
import { withStyles, WithStyles, createStyles } from '@material-ui/core/styles'
import groupBy from 'lodash/groupBy'
import { fromGlobalId } from 'graphql-relay'
import { ENTITIES_LABEL, ENTITIES_PLURAL_LABEL } from '~/constantes'
import EntityLink from '~/components/EntityLink'
import withWidth, { WithWidth } from '~/utils/withWidth'
import compose from 'recompose/compose'
import clsx from 'clsx'

const styles = createStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
  },
  horizontal: {
    maxHeight: 300,
    flexWrap: 'wrap',
    overflow: 'auto',
  },
  title: {
    fontSize: '1rem',
    fontWeight: 400,
    marginBottom: 10,
    marginRight: 5,
  },
  entityLink: {
    marginBottom: 5,
    marginRight: 5,
  },
})
type Entity = { node: { id: string; name: string; slug: string } }
interface OwnProps {
  entities: Entity[]
}
interface Props extends WithStyles<typeof styles>, WithWidth, OwnProps {
  noLink: boolean
  style: React.CSSProperties
}

const EntitiesRef = ({
  classes,
  style,
  entities,
  width,
  noLink = false,
}: Props) => {
  const entitiesByType = groupBy(entities, (d) => fromGlobalId(d.node.id).type)
  return (
    <div
      className={clsx(classes.root, {
        [classes.horizontal]: width !== 'xs',
      })}
      style={style}
    >
      {entitiesByType &&
        Object.keys(entitiesByType).map((key) => {
          return (
            <React.Fragment key={key}>
              <Typography className={classes.title}>
                {entitiesByType[key].length}{' '}
                {(entitiesByType[key].length > 1
                  ? ENTITIES_PLURAL_LABEL
                  : ENTITIES_LABEL)[key.toUpperCase()].toLowerCase()}
              </Typography>
              {entitiesByType[key].map(
                ({ node: { name, id, slug } }: Entity, index: number) => (
                  <div
                    key={id}
                    className={classes.entityLink}
                    style={{
                      marginBottom:
                        index + 1 === entitiesByType[key].length
                          ? 20
                          : undefined,
                    }}
                  >
                    {noLink ? (
                      <Typography>{name}</Typography>
                    ) : (
                      <EntityLink id={id} name={name} slug={slug} />
                    )}
                  </div>
                )
              )}
            </React.Fragment>
          )
        })}
    </div>
  )
}

export default compose<Props, OwnProps>(
  withStyles(styles),
  withWidth
)(EntitiesRef)
