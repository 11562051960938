import React from 'react'
import { graphql } from '@apollo/react-hoc'
import gql from 'graphql-tag'
import compose from 'recompose/compose'
import Typography from '@material-ui/core/Typography'
import get from 'lodash/get'
import asDefaultPopup from './asDefaultPopup'
import { ENTITIES_LABEL } from '~/constantes'
import EntityLink from '~/components/EntityLink'

class InstrumentView extends React.Component {
  render() {
    const {
      data: { instrument },
    } = this.props
    return (
      <div>
        <EntityLink
          name={instrument.name}
          id={instrument.id}
          slug={instrument.slug}
          noTooltip
        />
        <Typography>{ENTITIES_LABEL.Instrument}</Typography>
      </div>
    )
  }
}

export default compose(
  graphql(gql`
    query getInstrumentTooltip($objectId: ID!) {
      instrument(id: $objectId) {
        id
        name
        slug
        thumbnail
      }
    }
  `),
  asDefaultPopup((props) => ({
    thumbnail: get(props.data, 'instrument.thumbnail'),
    slug: get(props.data, 'instrument.slug'),
    padding: true,
  }))
)(InstrumentView)
