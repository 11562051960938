import React from 'react'
import { graphql } from '@apollo/react-hoc'
import gql from 'graphql-tag'
import compose from 'recompose/compose'
import Typography from '@material-ui/core/Typography'
import get from 'lodash/get'
import asDefaultPopup from './asDefaultPopup'
import { ENTITIES_LABEL } from '~/constantes'
import EntityLink from '~/components/EntityLink'
import EntityRef from '~/components/MetaTabs/EntitiesRef'
import uniqBy from 'lodash/uniqBy'

class TrackView extends React.Component {
  render() {
    const {
      data: { track },
    } = this.props
    return (
      <div>
        <EntityLink
          name={track.name}
          id={track.id}
          slug={track.slug}
          noTooltip
        />
        <Typography>{ENTITIES_LABEL.Track}</Typography>
        <EntityRef
          entities={uniqBy(
            [...track.bands.edges, ...track.authors.edges],
            'node.id'
          )}
          noLink
          style={{ marginTop: 20 }}
        />
      </div>
    )
  }
}

export default compose(
  graphql(gql`
    query getTrackTooltip($objectId: ID!) {
      track(id: $objectId) {
        id
        name
        slug
        thumbnail
        date
        authors {
          edges {
            node {
              id
              name
              slug
            }
          }
        }
        bands {
          edges {
            node {
              id
              name
              slug
            }
          }
        }
      }
    }
  `),
  asDefaultPopup((props) => ({
    thumbnail: get(props.data, 'track.thumbnail'),
    slug: get(props.data, 'track.slug'),
    padding: true,
  }))
)(TrackView)
