import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import ArrowRightIcon from '@material-ui/icons/ArrowRight'
import ArrowLeftIcon from '@material-ui/icons/ArrowLeft'
import * as mapActions from '~/ducks/map'
import clsx from 'clsx'
import Hidden from '@material-ui/core/Hidden'
import { MAP_WIDTHS, MAP_SIZES_STATUS, MENU_BAR_HEIGHT } from '~/constantes'
import Tooltip from '@material-ui/core/Tooltip'
import Box from '@material-ui/core/Box'
import Timeline from '~/components/Story/Context'
import MapIcon from '@material-ui/icons/Map'
import TimeIcon from '@material-ui/icons/Schedule'
import MapLoader from '~/components/Map/MapLoader'
import { useDispatch, useSelector } from '~/reducers'

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
  },
  mapIsSmall: {
    '& $sidebar': {
      maxWidth: `${MAP_WIDTHS[MAP_SIZES_STATUS.SMALL]}%`,
    },
  },
  mapIsLarge: {
    '& $sidebar': {
      maxWidth: `${MAP_WIDTHS[MAP_SIZES_STATUS.LARGE]}%`,
    },
  },
  sidebar: {
    flexGrow: 1,
    flexShrink: 0,
    maxWidth: 0,
    width: '100%',
    maxHeight: `calc(100vh - ${MENU_BAR_HEIGHT} * 3)`,
    position: 'sticky',
    top: `calc(${MENU_BAR_HEIGHT} * 2)`,
    zIndex: 1,
    boxSizing: 'border-box',
    transitionProperty: 'max-width',
    transitionDuration: '.5s',
  },
  changeMapSizeButtonsContainer: {
    position: 'absolute',
    top: 20,
    bottom: '10vh',
    right: 0,
    display: 'flex',
    flexDirection: 'column-reverse',
    zIndex: 1,
  },
  changeMapSizeButtons: {
    position: 'sticky',
    bottom: '10vh',
    display: 'flex',
    flexDirection: 'column',
    '& button': {
      margin: 0,
    },
  },
  body: {
    flexGrow: 1,
    minWidth: 0,
    zIndex: 3,
  },
}))

const PANEL_MAP = 0
const PANEL_TIMELINE = 1

interface Props {
  children: React.ReactElement
  withTimeline?: any
}

const LayoutWithStickyMapOnTheSide = ({ children, withTimeline }: Props) => {
  const classes = useStyles()
  const [currentPanelTab, setCurrentPanelTab] = React.useState(PANEL_MAP)
  const dispatch = useDispatch()
  const mapSize = useSelector((state) => state.map.size)
  const mapDisabled = useSelector((state) => state.map.disable)
  return (
    <div
      className={clsx({
        [classes.mapIsSmall]: mapSize === MAP_SIZES_STATUS.SMALL,
        [classes.mapIsLarge]: mapSize === MAP_SIZES_STATUS.LARGE,
      })}
    >
      <div className={classes.container}>
        <div className={classes.body}>
          <div style={{ minHeight: '100vh' }}>{children}</div>
        </div>
        {!mapDisabled && (
          <Hidden smDown>
            <div style={{ position: 'relative', zIndex: 3 }}>
              <div className={classes.changeMapSizeButtonsContainer}>
                <div className={classes.changeMapSizeButtons}>
                  {withTimeline && mapSize !== MAP_SIZES_STATUS.CLOSE && (
                    <React.Fragment>
                      <Tooltip placement="left" title="Carte">
                        <span>
                          <Button
                            variant="contained"
                            disabled={currentPanelTab === PANEL_MAP}
                            onClick={() => setCurrentPanelTab(PANEL_MAP)}
                          >
                            <MapIcon />
                          </Button>
                        </span>
                      </Tooltip>
                      <Tooltip placement="left" title="Frise">
                        <span>
                          <Button
                            variant="contained"
                            onClick={() => setCurrentPanelTab(PANEL_TIMELINE)}
                            disabled={currentPanelTab === PANEL_TIMELINE}
                          >
                            <TimeIcon />
                          </Button>
                        </span>
                      </Tooltip>
                    </React.Fragment>
                  )}
                  <Tooltip placement="left" title="Rétrécit la carte">
                    <span>
                      <Button
                        variant="contained"
                        onClick={() => dispatch(mapActions.setSmaller())}
                        disabled={mapSize === MAP_SIZES_STATUS.CLOSE}
                      >
                        <ArrowRightIcon />
                      </Button>
                    </span>
                  </Tooltip>
                  <Tooltip placement="left" title="Agrandit la carte">
                    <span>
                      <Button
                        variant="contained"
                        onClick={() => dispatch(mapActions.setLarger())}
                        disabled={mapSize === MAP_SIZES_STATUS.LARGE}
                      >
                        <ArrowLeftIcon />
                      </Button>
                    </span>
                  </Tooltip>
                </div>
              </div>
            </div>
            <div className={classes.sidebar}>
              {mapSize !== MAP_SIZES_STATUS.CLOSE && (
                <Box width="100%" height="100%" display="flex">
                  <Box overflow="auto" flexGrow={1}>
                    {currentPanelTab === PANEL_MAP && (
                      <MapLoader width="100%" height="100%" />
                    )}
                    {currentPanelTab === PANEL_TIMELINE && (
                      <Timeline postList={withTimeline.postList} />
                    )}
                  </Box>
                </Box>
              )}
            </div>
          </Hidden>
        )}
      </div>
    </div>
  )
}

export default LayoutWithStickyMapOnTheSide
