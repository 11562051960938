import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import Link from '~/components/Link'
import getEntityLink from '~/utils/getEntityLink'
import { fontSecondary } from '~/theme'
import Tooltip from '@material-ui/core/Tooltip'
import { fromGlobalId } from 'graphql-relay'
import PersonView from '~/components/Tooltips/PersonView'
import GroupView from '~/components/Tooltips/GroupView'
import TagView from '~/components/Tooltips/TagView'
import TrackView from '~/components/Tooltips/TrackView'
import InstrumentView from '~/components/Tooltips/InstrumentView'
import StyleView from '~/components/Tooltips/StyleView'
import Router from '~/utils/router'

const styles = (theme) => ({
  root: {
    color: theme.palette.text.primary,
    textDecoration: 'none',
    borderBottom: '1px dotted black',
    '&:hover': {
      borderBottom: '1px solid black',
    },
    marginRight: 10,
    display: 'inline',
    ...theme.typography.body1,
    fontSize: '1rem',
    fontWeight: 400,
    fontFamily: fontSecondary,
  },
  tooltipPopper: {
    opacity: 1,
  },
  tooltip: {
    color: theme.palette.text.primary,
    padding: 0,
    borderRadius: 0,
    boxShadow: '10px 10px 0px rgba(0,0,0,.1)',
    maxWidth: '25vw',
    minWidth: 150,
    backgroundColor: theme.palette.background.entity,
    opacity: 1,
  },
})

const tooltipComponents = {
  Person: PersonView,
  Group: GroupView,
  Tag: TagView,
  Track: TrackView,
  Instrument: InstrumentView,
  Style: StyleView,
}

const EntityLink = ({ classes, name, id, slug, noTooltip = false }) => {
  let { type } = fromGlobalId(id)
  let TooltipComponent = tooltipComponents[type]
  let { href, as } = getEntityLink(id, slug)
  let button = (
    <span>
      <Link href={href} as={as}>
        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
        <a
          className={classes.root}
          target="_parent"
          onMouseEnter={() => {
            Router.prefetch(href)
          }}
        >
          {name}
        </a>
      </Link>
    </span>
  )
  if (!noTooltip && TooltipComponent) {
    return (
      <Tooltip
        classes={{ popper: classes.tooltipPopper, tooltip: classes.tooltip }}
        title={<TooltipComponent objectId={id} />}
      >
        {button}
      </Tooltip>
    )
  } else {
    return button
  }
}

export default withStyles(styles)(EntityLink)
