import gql from 'graphql-tag'

export default gql`
  query getPostsForPostListContext($slug: String!) {
    postList(slug: $slug) {
      id
      posts {
        edges {
          node {
            id
            entities {
              edges {
                node {
                  ... on Track {
                    id
                    slug
                    type
                    name
                    startDate: date
                  }
                  ... on Person {
                    id
                    slug
                    type
                    name
                    thumbnail
                    startDate: birthDate
                    endDate: deathDate
                  }
                  ... on Group {
                    id
                    slug
                    type
                    name
                    thumbnail
                    startDate: startDate
                    endDate: endDate
                  }
                  ... on Instrument {
                    id
                    slug
                    type
                    name
                    thumbnail
                  }
                  ... on Style {
                    id
                    slug
                    type
                    name
                    thumbnail
                    styleStartDate: startDate
                    endDate: endDate
                  }
                  ... on Tag {
                    id
                    slug
                    type
                    name
                    thumbnail
                    startDate: startDate
                    endDate: endDate
                  }
                  ... on PostList {
                    id
                    slug
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`
